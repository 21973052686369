import { getElementCoordinates } from '@/utils';
export default {
  data: () => ({
    showActionsCard: false,
    selectedItem: {
    },
    positionX: 0,
    positionY: 0,
    menuTimeout: null,
  }),
  methods: {
    async setMenuItem({ item, event }) {
      if (!item) {
        console.error('No menu item??');
        return;
      }
      if (item.id === this.selectedItem?.id && this.showActionsCard) {
        return;
      }
      this.menuTimeout = setTimeout(() => {
        this.selectedItem = item || {
        };
        const { x = 0, y = 0 } = getElementCoordinates(event?.target);
        this.positionX = x;
        this.positionY = y + 12;
        this.showActionsCard = true;
        this.clearMenuTimeout();
      }, 0);
    },
    clearMenuTimeout() {
      clearInterval(this.menuTimeout);
      this.menuTimeout = null;
    },
  },
  beforeDestroy() {
    this.clearMenuTimeout();
  },
};
